$(function () {
  var logoHeight = $('header .header-top .logo').outerHeight() + 20

  $(window).on('scroll', function () {
    if ($(window).outerWidth() >= 768) {
      var scrollPosition = $(this).scrollTop()

      if (scrollPosition >= logoHeight) {
        $('header .header-top .logo').hide()
        $('header .header-top').addClass('scroller')
        $('header').css(
          'padding-top',
          $('header .header-top').outerHeight() + logoHeight,
        )
      } else {
        $('header .header-top .logo').show()
        $('header .header-top').removeClass('scroller')
        $('header').css('padding-top', '0')
      }
    }
  })
});
