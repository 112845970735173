window.initPersonSearchSmartphone = function () {
  if ($(window).outerWidth() < 768) {
    var viewportHeight = $(window).outerHeight();

    $("#person-search").css("bottom", "-" + viewportHeight + "px");
    $("#person-search").css("left", "0px");
    $("#person-search").css("top", "auto");
    $("#person-search").css("opacity", 1.0);

    var personsHeight =
      viewportHeight - $("#person-search .search").outerHeight();
    $("#person-search .persons").outerHeight(personsHeight);
  }

  $(window).on("resize", function () {
    if ($(window).outerWidth() < 768) {
      if ($("#person-search").hasClass("open")) {
        $("#person-search").removeClass("open");
        $("#person-search .toggle-button .fa").removeClass(
          "fa-arrow-circle-down"
        );
        $("#person-search .toggle-button .fa").addClass("fa-arrow-circle-up");
      }

      if ($("header .header-top").hasClass("scroller")) {
        $("header .header-top .logo").show();
        $("header .header-top").removeClass("scroller");
        $("header").css("padding-top", "0");
      }

      var viewportHeight = $(window).outerHeight();
      $("#person-search").css("bottom", "-" + viewportHeight + "px");
      $("#person-search").css("left", "0px");
      $("#person-search").css("top", "auto");

      var personsHeight =
        viewportHeight - $("#person-search .search").outerHeight();
      $("#person-search .persons").outerHeight(personsHeight);

      window.personInfoMaxHeight = 0;
      window.personImageMaxHeight = 0;
      $("#person-search .persons .person").each(function () {
        if ($(this).find(".info").outerHeight() > window.personInfoMaxHeight) {
          window.personInfoMaxHeight = $(this).find(".info").outerHeight();
        }

        var img = $(this).find(".image img");
        if (img) {
          window.setPersonImageHeight(img);
        }
      });
      $("#person-search .persons .person .info").outerHeight(
        window.personInfoMaxHeight
      );
    }
  });

  $("#person-search .toggle-button").on("click", function () {
    event.preventDefault();

    event.stopPropagation();

    if ($(window).outerWidth() < 768) {
      var viewportHeight = $(window).outerHeight();
      var toggleButtonHeight = $("#person-search .toggle-button").outerHeight();

      if ($("#person-search").hasClass("open")) {
        $("#person-search").removeClass("open");
        $("#person-search").css("left", "0px");
        $("#person-search").css("top", "auto");
        $("#person-search").animate(
          { bottom: "-" + viewportHeight + "px" },
          750,
          function () {
            $("#person-search .toggle-button .fa").removeClass(
              "fa-arrow-circle-down"
            );
            $("#person-search .toggle-button .fa").addClass(
              "fa-arrow-circle-up"
            );
          }
        );
      } else {
        $("#person-search").addClass("open");
        $("#person-search").css("left", "0px");
        $("#person-search").css("top", "auto");
        $("#person-search").animate(
          { bottom: 0 - toggleButtonHeight + "px" },
          750,
          function () {
            $("#person-search .toggle-button .fa").removeClass(
              "fa-arrow-circle-up"
            );
            $("#person-search .toggle-button .fa").addClass(
              "fa-arrow-circle-down"
            );
          }
        );
      }
    }

    return false;
  });

  window.initPersonSearching();
};
