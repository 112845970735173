/* Monkey-patch for header image */
$(function () {
  if ($('body').hasClass('followpage')) {
    var headerimage = $('header > div.header-bottom div.neos-nodetypes-image')

    if (isset(headerimage)) {
      var imgTag = headerimage.find('img[src]')

      if (isset(imgTag)) {
        var imageSrc = imgTag.attr('src')

        headerimage.css(
          'background',
          "url('" + imageSrc + "') no-repeat center",
        )
        headerimage.css('background-size', 'cover')
        imgTag.css('visibility', 'hidden')
      }
    }
  }
})
