window.initPersonSearching = function () {
  window.initPersonsCompetenceFilter();
  window.getPersons("api/persons");

  $(document.body).on(
    "submit",
    "#person-search .search .search-input form",
    function (event) {
      if (event.originalEvent !== undefined) {
        event.preventDefault();

        var length = $("#person-search .search .search-input form input").val()
          .length;
        if (length > 0) {
          window.getPersons(
            "api/persons/search/" +
              $("#person-search .search .search-input form input").val()
          );
        } else {
          window.getPersons("api/persons");
        }
      }
    }
  );

  $(document.body).on(
    "keyup",
    "#person-search .search .search-input form input",
    function () {
      var length = $(this).val().length;
      if (length > 2) {
        window.getPersons(
          "api/persons/search/" +
            $("#person-search .search .search-input form input").val()
        );
      } else {
        window.getPersons("api/persons");
      }
    }
  );
};
