window.addEventListener("load", function () {
  $(document.body).on("click", "#person-search", function (event) {
    event.stopPropagation();
  });

  $(document).on("click", function (event) {
    var personSearchWidth = $("#person-search").outerWidth();
    if ($(window).outerWidth() >= 768) {
      if ($("#person-search").hasClass("open")) {
        $("#person-search").removeClass("open");
        $("#person-search").css("bottom", "0px");
        $("#person-search").animate(
          { left: "-" + personSearchWidth + "px" },
          750,
          function () {
            $("#person-search .toggle-button .fa").removeClass(
              "fa-arrow-circle-down"
            );
            $("#person-search .toggle-button .fa").addClass(
              "fa-arrow-circle-up"
            );
          }
        );
      }
    }
  });

  window.initPersonSearch();
  window.initPersonSearchSmartphone();
},{ once: true });
