window.getPersons = function (url) {
  $.ajax({
    url: location.protocol + "//" + location.host + "/" + url,
    method: "GET",
    contentType: "application/json",
    dataType: "json",
  }).done(function (data) {
    $("#person-search .persons .row").html("");

    var i = 0;
    var results = data.results;
    $.each(results, function (index, value) {
      var string =
        '<div class="col-xs-6 col-sm-4 col-lg-3">' +
        '<div class="person">' +
        '<div class="image">' +
        (value.pictureUrl != ""
          ? '<img src="' +
            value.pictureUrl +
            '" class="img-responsive" onLoad="window.setPersonImageHeight(this)" border="0" />'
          : "") +
        "</div>" +
        '<div class="info">' +
        (value.competence != ""
          ? '<p class="competence">' + value.competence + "</p>"
          : "") +
        '<b class="name">' +
        value.firstname +
        " " +
        value.lastname +
        "</b>" +
        (value.phone != ""
          ? '<p class="communication">Tel: <a href="tel://' +
            value.phone.replace(/\s/g, "") +
            '">' +
            value.phone +
            "</a></p>"
          : "") +
        (value.mobile != ""
          ? '<p class="communication">Mobil: <a href="tel://' +
            value.mobile.replace(/\s/g, "") +
            '">' +
            value.mobile +
            "</a></p>"
          : "") +
        (value.fax != ""
          ? '<p class="communication">Fax: <a href="tel://' +
            value.fax.replace(/\s/g, "") +
            '">' +
            value.fax +
            "</a></p>"
          : "") +
        (value.mail != ""
          ? '<a href="mailto:' + value.mail + '" class="mail">Mail</a>'
          : "") +
        "</div>" +
        "</div>" +
        "</div>";

      $("#person-search .persons .row").append(string);

      i++;
      if (i == results.length) {
        $("#person-search .persons .person").each(function () {
          if (!window.personInfoMaxHeight) {
            window.personInfoMaxHeight = 0;
          }
          if ($(this).find(".info").outerHeight() > window.personInfoMaxHeight) {
            window.personInfoMaxHeight = $(this).find(".info").outerHeight();
          }
        });

        $("#person-search .persons .person .info").outerHeight(window.personInfoMaxHeight);
      }
    });
  });
};
