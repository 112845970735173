window.initPersonsCompetenceFilter = function () {
  $.ajax({
    url: location.protocol + "//" + location.host + "/api/persons/competences",
    method: "GET",
    contentType: "application/json",
    dataType: "json",
  }).done(function (data) {
    $("#person-search .search .filter-competence ul").html("");

    var i = 0;
    var results = data.results;
    $.each(results, function (index, value) {
      var string = "<li>" + '<a href="#">' + value + "</a>" + "</li>";

      $("#person-search .search .filter-competence ul").append(string);

      i++;
      if (i == results.length) {
        var viewportHeight = $(window).outerHeight();
        var personsHeight =
          viewportHeight - $("#person-search .search").outerHeight();
        $("#person-search .persons").outerHeight(personsHeight);
      }
    });
  });

  $(document.body).on(
    "click",
    "#person-search .search .filter-competence ul li a",
    function (event) {
      event.preventDefault();

      window.getPersons("api/persons/search/" + $(this).html());

      return false;
    }
  );

  $(document.body).on(
    "click",
    "#person-search .search .reset-button",
    function (event) {
      event.preventDefault();

      window.getPersons("api/persons");

      return false;
    }
  );
};
